<template>
    <div class="product-item" :class="{ 'effect': effect, 'product-item-short': short }">
        <div class="product-item-in blurred pe-none">
                <ProductImageTag :product="product"/>
                <div :class="{'product-item-img': true, 'product-item-img-no-product': true}">
                    <ProductImage :product="product" :small="false"></ProductImage>
<!--                    <div class="product-stock-indicator" v-if="!small">-->
<!--                        <ProductStockIndicator :product="product"></ProductStockIndicator>-->
<!--                    </div>-->
                </div>
                <span class="product-item-name text-start"><span>Loading...</span><span class="product-item-name-fade"></span></span>

            <div class="product-item-meta pb-0 pt-1">
                <span v-if="weight" class="font-size-12">
                {{ weightStore.format(product.fine_weight) || ' ' }}
              </span>
                <ProductPriceTable :product="product" :use-small-tag="false" :show-premium="showPremium" :showNoStock="false" table-class="pb-0"/>
            </div>

            <div v-if="showButtons" class="d-flex justify-content-between gap-2 pt-2">
                <CartButton v-if="1" :product="product" :small="small"/>
                <WatchlistButton :product_id="0"/>
            </div>
        </div>
    </div>
</template>

<script setup>


let product = {
    id: -1,
    images: [],
    quantity_prices: [],
    shop_availability: true,
    gross_price: 1000.99,
    net_price: 1000.99,
    fine_weight: 31.1,
    premium_percent: 10,
    premium_value: 10,
    currency:'EUR',
    vat_status: 'Gross, Inc. VAT'
};
const weightStore = useWeightStore();
</script>

<script>

import ProductStockIndicator from '../ProductStockIndicator.vue';
import ProductPriceTable from '../ProductPriceTable.vue';
import ProductImage from '../ProductImage.vue';
import ProductImageTag from "../ProductImageTag.vue";
import ProductLabel from '../ProductLabel.vue';
import WatchlistButton from '../Buttons/WatchlistButton.vue';
import CartButton from '../Buttons/CartButton.vue';
import NotifyButton from '../Buttons/NotifyButton.vue';
import ModalContent from "../../Modals/ModalContent.vue";



export default {

    components: {
        ModalContent,
        ProductLabel,
        ProductImage,
        ProductImageTag,
        ProductStockIndicator,
        ProductPriceTable,
        NotifyButton,
        WatchlistButton,
        CartButton,
    },
    props: {
        weight: {
            type: Boolean,
            default: true,
        },
        effect: {
            type: Boolean,
            default: true,
        },
        short: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        showButtons: {
            type: Boolean,
            default: true,
        },
        showPremium: {
            type: Boolean,
            default: false,
        },
        showQuantityPrices: {
            type: Boolean,
            default: true
        }
    },
};
</script>
